// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

var jQuery = require("jquery")
global.$ = global.jQuery = jQuery
window.$ = window.jQuery = jQuery

require("admin-lte")
require("bootstrap")
require("daterangepicker")
require("select2")

import "@mdi/font/scss/materialdesignicons.scss";
require("admin-lte/dist/css/adminlte.css")
require("bootstrap/dist/css/bootstrap.css")
require("daterangepicker/daterangepicker.css")
require("select2/dist/css/select2.css")

require("k2so/styles/bootstrap_override.scss")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("k2so/autoload")
require("k2so/date_picker")
require("k2so/select2")
require("k2so/tooltip")
