$(document).on('turbolinks:before-cache', function() {
  $('.js-select2-grouped-basic, .js-select2-basic').select2('destroy');
});

$(document).on('turbolinks:load', function() {
  let matchCustom = function(params, data) {
    // If there are no search terms, return all of the data
    if ($.trim(params.term) === '') {
      return data;
    }

    // Skip if there is no 'children' property
    if (typeof data.children === 'undefined') {
      return null;
    }

    if (data.text.toUpperCase().indexOf(params.term.toUpperCase()) > -1) {
      var modifiedData = $.extend({}, data, true);
      return modifiedData;
    }

    // `data.children` contains the actual options that we are matching against
    var filteredChildren = [];
    $.each(data.children, function (idx, child) {
      if (child.text.toUpperCase().indexOf(params.term.toUpperCase()) >= 0) {
        filteredChildren.push(child);
      }
    });

    // If we matched any of the timezone group's children, then set the matched children on the group
    // and return the group object
    if (filteredChildren.length) {
      var modifiedData = $.extend({}, data, true);
      modifiedData.children = filteredChildren;

      // You can return modified objects from here
      // This includes matching the `children` how you want in nested data sets
      return modifiedData;
    }

    // Return `null` if the term should not be displayed
    return null;
  }

  $('.js-select2-grouped-basic').select2({
    theme: 'classic',
    matcher: matchCustom,
    allowClear: true,
    placeholder: 'Selecione...'
  })

  $('.js-select2-basic').select2({
    theme: 'classic',
    allowClear: true,
    placeholder: 'Selecione...'
  })
})

