import moment from "moment"

$(document).on('turbolinks:load', function() {
  $('.js-date-range').each(function() {
    let elem = $(this)
    elem.daterangepicker({
      ranges: {
        'Hoje': [moment(), moment()],
        'Ontem': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Últimos 7 dias': [moment().subtract(6, 'days'), moment()],
        'Últmos 30 dias': [moment().subtract(29, 'days'), moment()],
        'No mês atual': [moment().startOf('month'), moment().endOf('month')],
        'Último mês': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      },
      startDate: $(elem.data('input-data-inicial')).val(),
      endDate: $(elem.data('input-data-final')).val(),
      locale: {
        format: 'DD/MM/YYYY',
        separator: " - ",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
        fromLabel: "De",
        toLabel: "Até",
        customRangeLabel: "Customizado",
        weekLabel: "S",
        daysOfWeek: [
            "Dom",
            "Seg",
            "Ter",
            "Qua",
            "Qui",
            "Sex",
            "Sab"
        ],
        monthNames: [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro"
        ],
        firstDay: 1
      }
    }, function(start, end, label) {
      if (elem.data('input-data-inicial')) {
        $(elem.data('input-data-inicial')).val(start.format('DD/MM/YYYY'))
      }

      if (elem.data('input-data-final')) {
        $(elem.data('input-data-final')).val(end.format('DD/MM/YYYY'))
      }
    })
  })

  $('.js-time-range').each(function() {
    let elem = $(this)
    elem.daterangepicker({
      ranges: {
        'Último minuto': [moment().subtract(1, 'minutes'), moment().endOf('day')],
        'Últimos 5 minutos': [moment().subtract(5, 'minutes'), moment().endOf('day')],
        'Últimos 30 minutos': [moment().subtract(30, 'minutes'), moment().endOf('day')],
        'Última hora': [moment().subtract(1, 'hours'), moment().endOf('day')],
        'Últimas 4 horas': [moment().subtract(4, 'hours'), moment().endOf('day')],
        'Últimas 24 horas': [moment().subtract(24, 'hours'), moment().endOf('day')],
        'Hoje': [moment().startOf('day'), moment().endOf('day')],
        'Últimos 7 dias': [moment().subtract(6, 'days'), moment().endOf('day')],
        'Últmos 30 dias': [moment().subtract(29, 'days'), moment().endOf('day')]
      },
      startDate: $(elem.data('input-data-inicial')).val(),
      endDate: $(elem.data('input-data-final')).val(),
      timePicker: true,
      timePicker24Hour: true,
      autoUpdateInput: false,
      locale: {
        format: 'DD/MM/YYYY HH:mm',
        cancelLabel: 'Cancelar',
        separator: " - ",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
        fromLabel: "De",
        toLabel: "Até",
        customRangeLabel: "Customizado",
        weekLabel: "S",
        daysOfWeek: [
            "Dom",
            "Seg",
            "Ter",
            "Qua",
            "Qui",
            "Sex",
            "Sab"
        ],
        monthNames: [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro"
        ],
        firstDay: 1
      }
    }, function(start, end, label) {
      if (elem.data('input-data-inicial')) {
        $(elem.data('input-data-inicial')).val(start.format('DD/MM/YYYY HH:mm'))
      }

      if (elem.data('input-data-final')) {
        $(elem.data('input-data-final')).val(end.format('DD/MM/YYYY HH:mm'))
      }
    })

    if ($(elem.data('input-data-inicial')).val() != "") {
      $(this).val($(elem.data('input-data-inicial')).val() + ' - ' + $(elem.data('input-data-final')).val())
    }

    elem.on('apply.daterangepicker', function(ev, picker) {
      $(this).val(picker.startDate.format('DD/MM/YYYY HH:mm') + ' - ' + picker.endDate.format('DD/MM/YYYY HH:mm'))
    })

    elem.on('cancel.daterangepicker', function(ev, picker) {
      $(this).val('')
      $(elem.data('input-data-inicial')).val('')
      $(elem.data('input-data-final')).val('')
    })
  })

  $('.js-date-picker').each(function() {
    let elem = $(this)
    elem.daterangepicker({
      singleDatePicker: true,
      showDropdowns: true,
      minYear: 1900,
      locale: {
        format: 'DD/MM/YYYY',
        separator: " - ",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
        fromLabel: "De",
        toLabel: "Até",
        customRangeLabel: "Customizado",
        weekLabel: "S",
        daysOfWeek: [
            "Dom",
            "Seg",
            "Ter",
            "Qua",
            "Qui",
            "Sex",
            "Sab"
        ],
        monthNames: [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro"
        ],
        firstDay: 1
      }
    })
  })
})
